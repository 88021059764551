import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from "./header.module.css";
import Headshot from './Media/Headshot.png';
import rugbyThrow from './Media/rugbyThrow.jpg';
import messageGen from './Media/messageGen.png';
import playlistForge from './Media/playlistForge.png';
import contact from './Media/contact.jpeg';
import sent from './Media/sent.jpeg';


function Header({ hideHeader }) {
  const navigate = useNavigate();

  const handleLinkClick = (route) => {
    hideHeader(); // Hide the header
    navigate(route); // Navigate to the specified route
  };

  return (
    <div className={styles.tiles} >

      <Link to="/work" className={styles.tile1} onClick={() => handleLinkClick('/work')}>
      <h3>View My Work</h3>
      <img src={messageGen} alt="Work" className={styles.messageGen} />
      <img src={playlistForge} alt="Work" className={styles.playlistForge} />

    </Link>

    <Link to="/about" className={styles.tile2} onClick={() => handleLinkClick('/about')}>
    <h3>Learn More About Me</h3>
    <img src={Headshot} alt="Work" className={styles.headshot} />
    <img src={rugbyThrow} alt="Work" className={styles.rugbyThrow} />

  </Link>
  <Link to="/contact" className={styles.tile3} onClick={() => handleLinkClick('/contact')}>
    <h3>Contact Me</h3>
    <img src={contact} alt="Work" className={styles.contact} />
    <img src={sent} alt="Work" className={styles.sent} />

</Link>



        </div>
  )
}
export default Header;
