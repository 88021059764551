import React, { useState } from 'react';
import styles from './about.module.css';
import Headshot from './Media/Headshot.png';
import Rugby1 from './Media/Rugby1.jpg';
import Rugby2 from './Media/Rugby2.jpg';
import Rugby3 from './Media/Rugby3.jpg';
import Rugby4 from './Media/Rugby4.jpg';
import Rugby5 from './Media/Rugby5.jpg';
import Rugby6 from './Media/Rugby6.jpg';
import Rugby7 from './Media/Rugby7.jpg';
import Rugby8 from './Media/Rugby8.jpg';
import Rugby9 from './Media/Rugby9.jpg';
import Rugby10 from './Media/Rugby10.jpg';
import Rugby11 from './Media/Rugby11.jpg';
import Rugby12 from './Media/Rugby12.jpg';
import Rugby13 from './Media/Rugby13.jpg';
import Rugby14 from './Media/Rugby14.jpg';
import Rugby15 from './Media/Rugby15.jpg';
import Rugby16 from './Media/Rugby16.jpg';
import Rugby17 from './Media/Rugby17.jpg';
import Rugby18 from './Media/Rugby18.jpg';
import Rugby19 from './Media/Rugby19.jpg';
import Rugby20 from './Media/Rugby20.jpg';
import Rugby21 from './Media/Rugby21.jpg';
 



function About() {

    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
    
        Rugby1,
        Rugby2,
        Rugby3,
        Rugby4,
        Rugby5,
        Rugby6,
        Rugby7,
        Rugby8,
        Rugby9,
        Rugby10,
        Rugby11,
        Rugby12,
        Rugby13,
        Rugby14,
        Rugby15,
        Rugby16,
        Rugby17,
        Rugby18,
        Rugby19,
        Rugby20,
        Rugby21,
        
      ];


      

  return (
    <div className={styles.App}>
        <header>
       
    </header>
    <body>

    <section id={styles.about}>

        
    <div class={styles.card1} >
    <div class={styles.Skills}>
    <img src={Headshot} alt="Work" className={styles.headshot} />

            <h2>Isa Sloot</h2>
            <ul>
              <h5>Software Development Skills</h5>
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>React</li>
                <h5>Modern Languages</h5>
                <li>Spanish</li>
                <li>Catalan</li>
                <li>French</li>
                <h5>Project Management</h5>
                <li>Agile project management experience</li>
                <li>1.5 years as Scrum Master at a tech start-up</li>
    
            </ul>
        </div>
           
        </div>

        <div class={styles.card2}>
        <h2>About Me</h2>
            <p>I have had a diverse professional journey that has ultimately led me to the world of programming and software development. With a background in project management and experience as a Scrum Master at a property tech company, I've spent the last year and a half mastering the art of efficient collaboration and delivery.

            My career path has been a unique one, spanning across various roles, including customer service, administration, and even a fulfilling role as a play worker at a nursery. While I hold a degree in Criminology, my true passion lies in the dynamic and ever-evolving world of technology.
            
            From an early age, I've been captivated by the latest gadgets and gaming, and I find endless fascination in all things tech. Software development, in particular, has become my canvas for creative expression, enabling me to transform ideas into tangible, interactive solutions.
            
            I'm thrilled to embark on this coding and web development journey, where I can combine my love for technology with the joy of creating something new and impactful. Let's explore this exciting world of software development together!
            
            </p>
            <div className={styles.workXP}>

            <ul>
              <h5>Previous Employment</h5>
              <h6>Cambridge RUFC, Shelford RFC - November 2022 to Present</h6>
                <li>Volunteer Rugby Coach</li>
                <h6>Yuno Group Limited - February 2022 to October 2023</h6>
                <li>Product and Data Executive</li>
                <h6>Tesco - November 2018 to December 2018</h6>
                <li>Christmas Colleague</li>
                <h6>Ashdon children's nursery Ltd - September 2017 to September 2018</h6>
                <li>Nursery Assistant</li>
                <h6>Marks and Spencer - July 2017 to September 2017</h6>
                <li>Customer Assistant</li>
                <h6>Deloitte - July 2016 to July 2017</h6>
                <li>Associate</li>
                
    
            </ul>
            </div>
    </div>

    <div class={styles.card3}>
    <h2>Hobbies</h2>
    <h3>Rugby</h3>
    <p>Active player at Shelford Rugby Club, formerly with Cambridge RUFC. Represented Cambridgeshire and Eastern Counties.</p>
    <div className={styles.imageSlider}>
    <div className={styles.imageContainer} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Image ${index + 1}`} />
      ))}
     {images.map((image, index) => (
        <img key={index} src={image} alt={`Image ${index + 1}`} />
      ))};
    </div>
  </div>


    </div>




    </section>

    
      </body>
      <footer className={styles.footer}>
    
      </footer>
    </div>
    )
  ;
}


export default About;
