// App.js
import React, { useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import { slideInDown, flip } from 'react-animations';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Root from "./components/Root";
import About from "./components/about";
import Work from "./components/work";
import Contact from "./components/contact";
import isa_svg from './Media/isa_svg.svg';
import styles from './app.module.css';
import Header from "./components/Header";

// ... (imports and other code)

function App() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slideInAnimation = keyframes`${slideInDown}`;
  const FlipAnimation = keyframes`${flip}`;

  const AnimatedDiv = styled.div`
    animation: ${slideInAnimation} 1s ease;
  `;

  const ButtonAnimation = styled.button`
    animation: ${FlipAnimation} 1s ease;
  `;

  const toggleHeaderVisibility = () => {
    setIsHeaderVisible(prev => !prev);
  };

  const title = () => {
    const pathName = window.location.pathname;
    if (pathName.includes('/about')) {
      return <h1>Hello, World!</h1>;
    } else if (pathName.includes('/work')) {
      return <h1>Projects</h1>;
    } else if (pathName.includes('/contact')) {
      return <h1>Contact</h1>;
    }
  };

  const WarningMessage = () => {
    if (screenWidth < 1080) {
      return (
        <div className={styles.warningMessage}>
          <p>
            Mobile view coming soon. Please use a desktop or a laptop to view this page.
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.App}>
      {screenWidth < 1080 && <WarningMessage />}
      {screenWidth >= 1080 && (
        <BrowserRouter>
          <header className={styles.headerContainer}>
            <div className={styles.buttonDiv}>
              <ButtonAnimation>
                <button className={styles.toggleHeaderButton} onClick={toggleHeaderVisibility}>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                </button>
              </ButtonAnimation>
            </div>
            <div className={styles.centeredContent}>
              <img src={isa_svg} className={styles["App-logo"]} alt="logo" />
              <h1>{title()}</h1>
            </div>
          </header>
          <AnimatedDiv>
            {isHeaderVisible && (
              <Header
                className={`${styles.header} ${isHeaderVisible ? styles.active : ''}`}
                hideHeader={() => setIsHeaderVisible(false)} // Pass down hideHeader function
              />
            )}
          </AnimatedDiv>

          <Routes>
            <Route path="/" element={<Root />} />
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
