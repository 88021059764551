import React, { useState } from "react";
import buildABear from "./messageGen";
import styles from "./work.module.css";

function Work() {
  const [randomSentence, setRandomSentence] = useState("");

  const generateSentence = () => {
    const sentence = buildABear();
    setRandomSentence(sentence);
  };

  return (
    <div className={styles.projects}>
      <h2>My First Project</h2>

       <div className={styles.iframeContainer}>
<p>My coding journey began after I suggested to my wife that she should create a website for her dog walking business. This started as an HTML/CSS project, but there are plans to re-create it in React.js.</p>
      <iframe

        src="https://pawpatrons.co.uk/"
        title="Introduction To WiseGPT"
        frameborder="0"
        sandbox="allow-scripts allow-same-origin"

      ></iframe>
    </div>
    <h2>Random Sentence Generator</h2>

      <div className={styles.messageGenContainer}>

        <div className={styles.generator}>
      <p>This was my first app using JavaScript. The code is very clunky, but it's part of the learning process</p>
      <button class={styles.buttonGen} role="button" onClick={generateSentence}>Generate sentence</button>
      {randomSentence && <p className={styles.message}>{randomSentence}</p>}
      </div>
      </div>
      <h2>My First React.js Project</h2>

      <div className={styles.iframeContainer}>
      <iframe

        src="https://playlistforge.netlify.app/"
        title="Introduction To WiseGPT"
        frameborder="0"
        sandbox="allow-scripts allow-same-origin"

      ></iframe>
    </div>
    </div>
  );
}

export default Work;
